import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { injectIntl } from 'react-intl';

import * as styles from './professionals.module.scss';
import ProfessionalsGallery from './professionalsGallery';

const Professionals = ({ intl: { formatMessage } }) => {
  return (
    <div className={styles.professionals}>
      <div className={styles.background_images}>
        <StaticImage
          src="../../../images/aboutUs/arrow_10.webp"
          alt="arrow"
          className={styles.professionals__arrow_1}
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
        />
      </div>
      <h2 className="title">
        {formatMessage({ id: 'aboutUsProfessionalsTitle' })}
      </h2>
      <ProfessionalsGallery />
    </div>
  );
};

Professionals.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Professionals);
